import * as React from "react";
import moment from 'moment';
import t from "../hooks/useTranslation";
import AuthService from "../AuthService";
import Photo360Viewer from "./Photo360Viewer";


import './PhotoDetail.css';
import Error from "../components/Error";

const authService = new AuthService();

function PhotoDetailMain({error, photo, handleModalOpen, handleHistoryShootingModalOpen, delete360}) {
    const isSuperAdmin = authService.isSuperAdmin();

    const renderExtraParams = () => {
        const rows = [];
        photo?.extraParams?.forEach(({name, value}) => {
            rows.push(<tr>
                <th>{name}</th>
                <td>{value}</td>
            </tr>);
        });
        // alternativeItems
        photo?.itemConfig?.alternativeItems && Object.entries(photo.itemConfig.alternativeItems).forEach(([name, value]) => {
            console.log(name, value);
            rows.push(<tr>
                <th>{name}</th>
                <td>{value}</td>
            </tr>);
        });

        if (rows.length) {
            return (<>
                <h4>Volitelná pole navíc</h4>
                <table>
                    <tbody>{React.Children.toArray(rows)}</tbody>
                </table>
            </>);
        }
    }

    return (
        <div className="PhotoDetailMain app__detail__main">
            <div className="app__title">
                <h1>{t('photoDetail')}</h1>
                <p>{`${t('photoCode')}: ${photo.photoName}`}</p>
            </div>
            <Photo360Viewer photo={photo}/>
            <div className="app__box app__box--wide app__info">
                <div className="sub__container">
                    <div className="app__info__item">
                        <table>
                            <tbody>
                            <tr>
                                <th>{t('photoCode')}</th>
                                <td>{photo.photoName}</td>
                            </tr>
                            <tr>
                                <th>{t('toRephoto')}</th>
                                <td>{t(photo.toRephoto ? 'yes' : 'no')}</td>
                            </tr>
                            {
                                photo.rephotoNote && <tr>
                                    <th>{t('rephotoNote')}</th>
                                    <td>{photo.rephotoNote}</td>
                                </tr>
                            }
                            <tr>
                                <th>{t('readyToDownload')}</th>
                                <td>{t(photo.checked ? 'yes' : 'no')}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="app__info__item">
                        <table>
                            <tbody>
                            <tr>
                                <th>{t('toReview')}</th>
                                <td>{t(photo.toReview ? 'yes' : 'no')}</td>
                            </tr>
                            <tr>
                                <th>{t('uploadedAt')}</th>
                                <td>{photo.uploadedAt ? moment(photo.uploadedAt).format('DD.MM.YYYY HH:mm') : '-'}</td>
                            </tr>
                            <tr>
                                <th>{t('createdAt1')}</th>
                                <td>{moment(photo.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                            </tr>
                            {isSuperAdmin && <tr>
                                <th>{t('tookPicture')}</th>
                                <td>{photo.createdBy?.name} ({photo.client?.name})</td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    {renderExtraParams()}
                </div>
            </div>

            <div className="app__footer">
                <div className="spacing">
                    <button className="button white-shadow icon" onClick={delete360}>Smazat položku
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M20.37,8.91L19.37,10.64L7.24,3.64L8.24,1.91L11.28,3.66L12.64,3.29L16.97,5.79L17.34,7.16L20.37,8.91M6,19V7H11.07L18,11V19A2,2 0 0,1 16,21H8A2,2 0 0,1 6,19M8,19H16V12.2L10.46,9H8V19Z"/>
                        </svg>
                    </button>
                    <button className="button white-shadow icon" onClick={handleModalOpen}>
                        {t('updatePhotoCode')}
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"/>
                        </svg>
                    </button>
                    <button className="button white-shadow icon" onClick={handleHistoryShootingModalOpen}>
                        {t('showShootingHistory')}
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"/>
                        </svg>
                    </button>
                </div>
            </div>

            {error && <Error error={error}/>}

        </div>
    );
}

export default PhotoDetailMain;
